import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { Field } from "../../../../components/fouladyar/field/field";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { CgClose } from "react-icons/cg";
import axios from "../../../../shared/custome-axios";
import { ErrorToaster } from "../../../../shared/toaster";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const ForgetPassword = () => {

  const { t, i18n } = useTranslation();

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: ""
  });

  async function onSubmit() {
    try {

      if (!data.email) {
        toast.error(t(`please-fill-in-the-email-address`));
      }

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/forget-password/client`, data, {
        headers: { authorization: `bearer ${auth.token}` }
      });

      if (res.status === 200) {
        toast.success(t(`a-password-change-email-will-be-sent-to-you-please-check-your-emails`));
        navigate(`/login`);
      }

    } catch (e) {
      ErrorToaster(e);
    }
  }


  return (
    <>
      <SafeAreaHeader
        rightIcon={<CgClose size={24} color={"#060606"} />}
        rightIconOnclick={() => {
          navigate("/login");
        }}
      />

      <div className="scroll-fixed-area">
        <div className="scroll-container">
          <div className="container">

            <div className="row d-flex">
              <h6 className="profile-title p-1 text-center">{t("forgot-password")}</h6>
            </div>

            <div className="row  mb-4">
              <p className="text-center">
                {t("please-enter-your-login-email-to-receive-the-password-reset-link")}
                {/*<b> le lien de réinitialisation de votre mot de passe.</b>*/}
              </p>
            </div>


            <div className="row mb-2">
              <Field
                id={"email"}
                name={"email"}
                label={t("login") + " " + t("email")}
                type={"text"}
                value={data.email}
                onChange={(e) => {
                  setData(prevState => ({
                    email: e
                  }));
                }}
              />
            </div>


          </div>
          <div className="container mb-4">
            <div className={`btn blue-button`} onClick={async () => {
              await onSubmit();
            }}>
              {t('receive-link')}
            </div>
          </div>

          <div className="row d-flex">
            <div className="p-1 text-center">{t("if-you-need-assistance")}</div>
          </div>

          <div className="row d-flex justify-center">
            <a className="col-sm-auto pt-0 pt-0 p-1 m-0 text-center">{t("please-contact-us")}</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
