import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { Field } from "../../../../components/fouladyar/field/field";
import toast from "react-hot-toast";
import axios from "axios";
import { ErrorToaster } from "../../../../shared/toaster";
import SafeAreaHeader from "../../../patient/components/safeAreaHeader";
import { BiChevronLeft } from "react-icons/bi";


const DoctorForgetPassword = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: ''
  })

  async function onSubmit(){
    try {

      if(!data.email){
        toast.error(  `veuillez remplir l'adresse email`)
      }

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/forget-password/provider`, data, {
        headers: { authorization: `bearer ${auth.token}` }
      });

      if (res.status === 200) {
        toast.success(  `Un email de changement de mot de passe vous sera envoyé. Merci de consulter vos emails`)
        navigate(`/login`);
      }

    }catch (e){
      ErrorToaster(e)
    }
  }


  function ForgetPasswordForm({}){
    return(
      <div className="container">


        <div className="row d-flex">
          <h6 className="profile-title p-1 text-center" >{'Mot de passe oublié'}</h6>
        </div>

        <div className="row  mb-4">
          <p className="text-center">
            Veuillez saisir votre E-mail de connexion afin de recevoir
            <b> le lien de réinitialisation de votre mot de passe.</b>
          </p>
        </div>


        <div className="row mb-2">
          <Field
            id={"email"}
            name={"email"}
            label={"E-mail de connexion"}
            type={"text"}
            value={''}
            onChange={(e) => { }}
          />
        </div>


      </div>
    )
  }




  return (
    <div className="nk-content-auth pt-5">
      <div className="container-fluid-doctor">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block d-flex justify-center align-center">
              <div className="container ">
                <div className="ps-3 pe-3">
                  <SafeAreaHeader
                    leftIcon={<BiChevronLeft size={28} color={"#555555"} />}
                    leftIconOnclick={()=>{
                      navigate(-1);
                    }}
                  />



                  <div className="container">


                    <div className="row d-flex  mt-5">
                      <h6 className="profile-title p-1 text-center" >{'Mot de passe oublié'}</h6>
                    </div>

                    <div className="row mb-4">
                      <p className="text-center">
                        Veuillez saisir votre E-mail de connexion afin de recevoir
                        <b> le lien de réinitialisation de votre mot de passe.</b>
                      </p>
                    </div>


                    <div className="row mb-2">
                      <Field
                        id={"email"}
                        name={"email"}
                        label={"E-mail de connexion"}
                        type={"text"}
                        value={data.email}
                        onChange={(e) => {
                          setData(prevState => ({
                            email: e
                          }))
                        }}
                      />
                    </div>


                  </div>

                  <Link to={`${process.env.PUBLIC_URL}/email-sent`}>
                    <div className="container mb-4">
                      <div className={`btn blue-button`} onClick={async () => {
                        await onSubmit()
                      }}>
                        Recevoir le lien
                      </div>
                    </div>
                  </Link>

                  <div className="row d-flex">
                    <div className="p-1 text-center" >Si vous avez besoin d'assistance</div>
                  </div>

                  <div className="row d-flex justify-center">
                    <Link to={`${process.env.PUBLIC_URL}/support`}>
                      <div  className="col-sm-auto pt-0 pt-0 p-1 m-0  d-flex justify-center" >veuillez nous contacter</div>
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorForgetPassword;
