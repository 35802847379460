import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiChevronRight, FiMapPin, FiPhone, FiUsers } from "react-icons/fi";
import { LuCalendar, LuClock4 } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { IoClose } from "react-icons/io5";
import axios from "../../../../shared/custome-axios";
import { EmptyState } from "../../../../components/fouladyar/empty-state/emptyState";
import EmptyStateImage from "../../../../assets/images/cliconsult/empty-states/history-empty-state.png";
import { validateToken } from "../../../auth";
import toast, { Toaster } from "react-hot-toast";
import { deleteTicketByAppointmentId } from "../../../../redux/store/services/ticket/store";
import moment from "moment/moment";
import { ErrorToaster } from "../../../../shared/toaster";
import { setSelectedItem } from "../../../../redux/store/services/search/store/search-actions";

const History = () => {
  const auth = useSelector((state) => state.auth);
  const ticket = useSelector((state) => state.ticket);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [activeTicket, setActiveTicket] = useState([]);
  const [history, setHistory] = useState([
    // {
    //   clientSubjectId: "22fe09cf-9c55-4ac5-b02e-392c6d0989f6",
    //   appointmentDate: "2023-11-23",
    //   visitTime: "23:23",
    //   specialization: "GYNECOLOGY",
    //   providerType: "DOCTOR",
    //   numOfPersons: 1,
    //   appointmentStatus: "WAITING"
    // },
  ]);

  debugger;

  function checkIfIsOver24Hours(appointment_date) {
    const appointmentDate = new Date(appointment_date);

    // Step 2: Get the current date and time
    const currentDate = new Date();

    // Step 3: Calculate the time difference in milliseconds
    const timeDifference = appointmentDate - currentDate;

    // Step 4: Check if the time difference is greater than 24 hours (in milliseconds)
    const isOver24Hours = Math.abs(timeDifference) > (24 * 60 * 60 * 1000);

    if (isOver24Hours) {
      console.log("The appointment is over 24 hours from now.");
      return true;
    } else {
      console.log("The appointment is within the next 24 hours.");
      return false;
    }
  }


  useEffect(() => {
    console.log("ticket", ticket);

    async function loadItems() {
      try {

        const isTokenValid = await validateToken(auth.token);
        setTokenIsValid(isTokenValid || false);


        if (isTokenValid) {
          const activeTicketResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/client/appointment/active`, {
            headers: { "authorization": `bearer ${auth.token}` }
          });
          console.log("activeTicketResponse.data", activeTicketResponse.data);

          if (activeTicketResponse.status === 200) {
            setActiveTicket(activeTicketResponse.data);
          }


          const historyResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/client/history`, {
            headers: { "authorization": `bearer ${auth.token}` }
          });

          console.log("historyResponse.data", historyResponse.data);

          if (historyResponse.status === 200) {
            setHistory(historyResponse.data);
          }

        } else {
          console.log("active ticket non login", ticket);
          // appointmentDate
          ticket.active.map(j => {
            if (checkIfIsOver24Hours(j.appointmentDate)) {
              toast.error(t("your-ticket-is-expired"));
              dispatch(deleteTicketByAppointmentId(j.appointmentId));
            }
          });
          setActiveTicket(ticket.active);
        }

      } catch (e) {
        ErrorToaster(e);
      }
    }

    loadItems();
  }, []);

  function ActiveTicketContainer() {
    function ActiveTicketItem({ item }) {
      async function checkIfTicketIsValid({ providerSubjectId, appointmentId }) {

        try {
          const ticket = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/client/appointment/${providerSubjectId}/${appointmentId}`, {
            headers: { "authorization": `bearer ${auth.token}` }
          });


          console.log("ticket.data.status%%%%%%%%%%%%%%%%%%%%%%%%%%", ticket.data.status);
          if (!(ticket.data.status === "WAITING" || ticket.data.status === "VISITING")) {
            dispatch(deleteTicketByAppointmentId(appointmentId));
            console.log("tickets", ticket);
            const isTokenValid = await validateToken(auth.token);
            if (isTokenValid) {
              setActiveTicket(prevState => ([
                ...(prevState.filter(item => item.appointmentId !== appointmentId))
              ]));
            }
            if (ticket.status)
              //تیکت مورد نظر شما به دلیل منقضی شدن از سیستم حذف شد
              toast.error(t("the-desired-ticket-has-been-deleted-from-the-system-due-to-expiration"));
            return false;
          }

          return true;
        } catch (e) {
          ErrorToaster(e);
        }


      }


      return (
        <div
          className={`container history-item active-ticket-item-${item.providerType?.toLowerCase() || "doctor"}-background`}>

          <div className="d-flex flex-row justify-content-between pt-2">
            <div className="w-auto d-flex justify-start align-center">
              <h6 className="search-item-title">{item.clientFullName || "UNKNOWN_CLIENT_NAME"}</h6>
            </div>
            <div className="w-auto d-flex justify-end align-center p-0">
              <div className="row history-item-row-container m-0">
                <div
                  className={`history-item-status ${item.appointmentStatus?.toLowerCase()}_status col-sm-auto`}>{t(item.appointmentStatus)}</div>
              </div>
            </div>
          </div>

          <div className={`divider mt-1 mb-2 divider-${item.providerType?.toLowerCase() || "doctor"}-background`}></div>

          <div className="d-flex flex-row ">
            <div className="w-auto">
              <div className={`vertical-status-line ${item.providerType?.toLowerCase() || "doctor"}-background`}></div>
            </div>
            <div className="w-100 ps-2">
              <div className="d-flex flex-row justify-content-between ">
                <div className="w-auto" style={{ lineHeight: 2, paddingLeft: 0 }}>
                  <h6
                    className="search-item-title">{`${item.providerGender === "MALE" ? "M. " : (item.providerGender === "FEMALE" ? "Mme " : "Inconnu ")}${item.doctorOrProfessor === "DOCTOR" ? "Dr. " : (item.doctorOrProfessor === "PROFESSOR" ? "Prof. " : "")}${item.providerFullName}`}</h6>
                  <p
                    className={`search-item-expertise ${item.providerType?.toLowerCase() || "doctor"}-color`}>{t(item.specialization || item.providerType.toLowerCase())}</p>

                </div>
                <div className="w-auto d-flex justify-end align-start">
                  <div className="more-about-history" onClick={() => {
                    dispatch(setSelectedItem(item));
                    navigate(`/doctor-profile/${item.providerSubjectId}`);
                  }}>
                    <FiChevronRight size={22} color={"#fff"} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between p-0 pt-2 pb-1 ">
            <div className="" style={{ width: "30%" }}>
              <div className="row d-flex flex-row justify-center align-center">
                <div className="w-auto p-1 pt-0 pb-0">
                  <LuCalendar size={18} color={"#484848"} />
                </div>
                <p className="w-auto p-0">{item.appointmentDate || moment().format("DD-YY-YYYY")}</p>
              </div>
            </div>
            <div className="" style={{ width: "30%" }}>
              <div className="row d-flex flex-row justify-center align-center">
                <div className="w-auto p-1 pt-0 pb-0 ">
                  <LuClock4 size={18} color={"#484848"} />
                </div>
                <p className="w-auto p-0">{item.visitTime}</p>
              </div>
            </div>
            <div className="" style={{ width: "30%" }}>
              <div className="row d-flex flex-row justify-center align-center">
                <div className="w-auto p-1 pt-0 pb-0">
                  <FiUsers size={18} color={"#484848"} />
                </div>
                <p className="w-auto p-0">{item.numOfPersons} Person</p>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between p-0 pt-2 pb-1 ">
            <div className={`btn button-no-color mt-0 ${item.providerType?.toLowerCase() || "doctor"}-background`}
                 onClick={async () => {
                   //check if this ticket is still valid or in wating state, then if it's valid, route to ticket page
                   if (await checkIfTicketIsValid({
                     providerSubjectId: item.providerSubjectId,
                     appointmentId: item.appointmentId
                   })) {
                     navigate(`/ticket/${item.providerSubjectId}/${item.appointmentId}`);
                   }
                 }}>
              Afficher plus
            </div>
          </div>
        </div>
      );
    }


    return (
      <div className="history-result-container">

        {
          !tokenIsValid ?
            ticket.active.map(item => {
              return (
                <ActiveTicketItem item={item} />
              );

            }) :
            activeTicket.map(item => {
              return (
                <ActiveTicketItem item={item} />
              );
            })
        }
      </div>
    );
  }

  function HistoryContainer() {
    function HistoryItem({ item }) {

      // clientSubjectId: "22fe09cf-9c55-4ac5-b02e-392c6d0989f6",
      //   appointmentDate: "2023-11-23",
      //   visitTime: "23:23",
      //   specialization: "GYNECOLOGY",
      //   providerType: "DOCTOR",
      //   numOfPersons: 1,
      //   appointmentStatus: "VISITED"
      console.log("item.providerSubjectId", item.providerSubjectId);

      return (
        <div className="container history-item">

          <div className="d-flex flex-row justify-content-between pt-2">
            <div className="w-auto d-flex justify-start align-center">
              <h6 className="search-item-title">{item.clientFullName || "UNKNOWN_CLIENT_NAME"}</h6>
            </div>
            <div className="w-auto d-flex justify-end align-center p-0">
              <div className="row history-item-row-container m-0">
                <div
                  className={`history-item-status ${item.appointmentStatus.toLowerCase()}_status col-sm-auto`}>{t(item.appointmentStatus)}</div>
              </div>
            </div>
          </div>

          <div className="divider mt-1 mb-2"></div>

          <div className="d-flex flex-row ">
            <div className="w-auto">
              <div className={`vertical-status-line ${item.providerType?.toLowerCase() || "doctor"}-background`}></div>
            </div>
            <div className="w-100 ps-2">
              <div className="d-flex flex-row justify-content-between ">
                <div className="w-auto" style={{ lineHeight: 2, paddingLeft: 0 }}>
                  <h6
                    className="search-item-title">{`${item.providerGender === "MALE" ? "M. " : (item.providerGender === "FEMALE" ? "Mme " : "Inconnu ")}${item.doctorOrProfessor === "DOCTOR" ? "Dr. " : (item.doctorOrProfessor === "PROFESSOR" ? "Prof. " : "")}${item.providerFullName}`}</h6>
                  {/*<h6 className="search-item-title">{item.providerFullName || "UNKNOWN_PROVIDER_NAME"}</h6>*/}
                  <p
                    className={`search-item-expertise ${item.providerType?.toLowerCase() || "doctor"}-color`}>{t(item.specialization)}</p>

                  {/*<div className="row search-item-location pb-1">
                    <div className="col-sm-auto w-auto padding-right-1">
                      <FiMapPin size={15} color={"#484848"}/>
                    </div>
                    <p className="col p-0">{item.location || "LOCATION_IS_NOT_AVAILABLE"}</p>
                  </div>

                  <div className="row search-item-location">
                    <div className="col-sm-auto w-auto padding-right-1">
                      <FiPhone  size={15}  color={"#484848"}/>
                    </div>
                    <p className="col p-0">{item.mobileNumber || "MOBILE_NUMBER_IS_NOT_AVAILABLE"}</p>
                  </div>*/}

                </div>

                <div className="w-auto d-flex justify-end align-start">
                  <div className="more-about-history" onClick={() => {
                    dispatch(setSelectedItem(item));
                    navigate(`/doctor-profile/${item.providerSubjectId}`);
                  }}>
                    <FiChevronRight size={22} color={"#fff"} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between p-0 pt-2 pb-1 ">
            <div className="" style={{ width: "30%" }}>
              <div className="row d-flex flex-row justify-center align-center">
                <div className="w-auto p-1 pt-0 pb-0">
                  <LuCalendar size={18} color={"#484848"} />
                </div>
                <p className="w-auto p-0">{item.appointmentDate}</p>
              </div>
            </div>

            {/*<div className="" style={{width: "30%"}}>*/}
            {/*  <div className="row d-flex flex-row justify-center align-center">*/}
            {/*    <div className="w-auto p-1 pt-0 pb-0 ">*/}
            {/*      <LuClock4 size={18}  color={"#484848"}/>*/}
            {/*    </div>*/}
            {/*    <p className="w-auto p-0">{item.visitTime}</p>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="" style={{ width: "30%" }}>
              <div className="row d-flex flex-row justify-center align-center">
                <div className="w-auto p-1 pt-0 pb-0">
                  <FiUsers size={18} color={"#484848"} />
                </div>
                <p className="w-auto p-0">{item.numOfPersons} Person</p>
              </div>
            </div>

          </div>


        </div>
      );
    }


    return (
      <div className="history-result-container">
        {
          history.map(item => {
            return (
              <HistoryItem item={item} />
            );
          })
        }
      </div>
    );
  }

  return (

    <>
      <SafeAreaHeader
        title={t("e-Ticket")}
      />

      <div className="scroll-fixed-area">
        <div className="scroll-container" style={{ padding: "0px" }}>
          {
            (history.length === 0 && activeTicket.length === 0 && ticket.active.length === 0) ?
              <EmptyState title={t("no-result")} image={EmptyStateImage} /> :
              <>


                <SafeAreaHeader title={t("active")} styles={{ position: "relative" }} />
                {
                  activeTicket.length === 0 ?
                    <EmptyState
                      title={"Vous n'avez pas e-Ticket actif."}
                      containerStyle={{ height: "auto" }}
                      imageStyle={{ maxWidth: "130px" }}
                      image={EmptyStateImage}
                    /> :
                    <>
                      <ActiveTicketContainer />
                    </>
                }


                <SafeAreaHeader title={t("history")} styles={{ position: "relative" }} />
                {
                  history.length === 0 ?
                    <EmptyState
                      title={"you-have-not-appointment-yet"}
                      containerStyle={{ height: "auto" }}
                      imageStyle={{ maxWidth: "130px" }}
                      image={EmptyStateImage}
                    /> :
                    <>
                      <HistoryContainer />
                    </>
                }
              </>

          }


        </div>
      </div>
    </>
  );
};

export default History;
