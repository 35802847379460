import { Icon } from "../../../../components/Component";
import React from "react";
import { FiHelpCircle } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function AlertMessage({onClose}) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  return (
    <div>
      <div className="row d-flex justify-between" style={{flexWrap: "unset"}}>
        <div className="col-sm-auto  w-auto align-center">
          <h4 style={{ padding: "0px 20px", fontSize: 15 }}>{t("delete-your-account")}</h4>
        </div>
        <div className="col-sm d-flex justify-end w-auto flex-grow-1">
          <div className="btn" onClick={()=> {onClose(true)}}>
            <MdOutlineClose  size={20} color={"#555555"} />
          </div>
        </div>
      </div>
      <li className="divider" style={{ margin: 0 }}></li>


      <div className="text-center" style={{ margin: "30px 15px" }}>
        {t("by-deleting-the account-all-of-your-data")}
      </div>


      <div className="d-flex justify-center align-center pt-2 pb-1">
        <div className={`btn blue-button ms-2 me-2`} onClick={() => {
          navigate('/delete-account')
        }}>
          {t("delete-my-account-and-data")}
        </div>
      </div>

    </div>
  );
}
