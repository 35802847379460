import ThemeProvider from "./layout/provider/Theme";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { configureApp } from "./redux/configs";
import { I18nextProvider, useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import * as en_US from "./locales/en_US.json";
import * as fa_IR from "./locales/fa_IR.json";
import * as fr_FR from "./locales/fr_FR.json";
import Router from "./route";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import * as i18n from "i18next";
import i18next from "i18next";

const defaultLang = localStorage.getItem("lang");
const configs = configureApp();
export const { store, persistor } = configs;
i18next.init({
  lng: defaultLang ? defaultLang : "fr", // if you're using a language detector, do not define the lng option
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false
  },
  resources: {
    en: en_US,
    fa: fa_IR,
    fr: fr_FR
  }// React already does escaping
});

export const apps = {
  patient: "patient",
  doctor: "doctor",
  none: "none"
};

const App = () => {

  const { t, i18n } = useTranslation();

  const lang = localStorage.getItem("lang");
  if (!lang) {
    localStorage.setItem("lang", "fr");
    /*i18n.changeLanguage("fr").then(() => {
      localStorage.setItem("lang", "fr");
    });*/
  }

  console.log("running app");
  useEffect(() => {
    const messageListener = (nativeEvent) => {
      try {
        let nativeReceivedData;

        if (typeof nativeEvent.data === "object") {
          nativeReceivedData = nativeEvent.data;
        } else {
          nativeReceivedData = JSON.parse(nativeEvent.data);
        }

        if (!nativeReceivedData) {
          console.log("no data from app");
          return;
        }

        if (nativeReceivedData.Type === "firebaseToken") {
          localStorage.setItem("firebaseToken", nativeReceivedData.token);
          localStorage.setItem("deviceType", nativeReceivedData.deviceType);
        }
      } catch (e) {
        console.log("error parsing JSON from native app:", e);
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  return (
    <ThemeProvider>
      <IconContext.Provider value={{ color: "blue", className: "global-class-name" }}>
        <Provider store={configs.store}>
          <PersistGate loading={null} persistor={configs.persistor}>
            <Toaster
              position="top-center"
              reverseOrder={true}
            />
            <I18nextProvider i18n={i18next}>
              <Router app={process.env.REACT_APP_LAYOUT} />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </IconContext.Provider>
    </ThemeProvider>
  );
};
export default App;
