import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { LuDownload, LuPrinter } from "react-icons/lu";
import ModalHelper from "../../../../components/fouladyar/modal-helper/modalHelper";
import HeaderDoctor from "../../../../layout/header/Header-doctor";
import { Field } from "../../../../components/fouladyar/field/field";
import { BiLink } from "react-icons/bi";
import { QRCodeCanvas } from "qrcode.react";
import { convertToTitleCase } from "../../../../shared/textTools";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import * as PropTypes from "prop-types";
import toast from "react-hot-toast";



export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <h1 ref={ref}>{props.component}</h1>
  );
});
ComponentToPrint.propTypes = { ref: PropTypes.func };
const QrCode = () => {
  const profile = useSelector((state) => state.profile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState(<div>empty</div>);
  const [data, setData] = useState(`${process.env.REACT_APP_PATIENT_URL}/doctor-profile/${profile.subjectId || ""}`);
  const qrCodeRef = useRef();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  useEffect(() => {
    // Function to update screenWidth when the window is resized
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };


    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);




  const downloadQRCode = () => {
    const canvas = document.querySelector("#qrcode-canvas");
    if (!canvas) throw new Error("<canvas> not found in the DOM");

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  function copyToClipboard(text) {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    document.body.removeChild(textField);
    toast.success('Lien copié avec succès')
  }




  function QRCodeWidthTitle(){
    return(
      <div id="print-preview">
        <div className="row d-flex justify-center align-center qr-title pb-4 ">QR Code unique du
          {`
            ${profile.gender === "MALE" ? "M." : (profile.gender === "FEMALE" ? "Mme" : "Inconnu")} 
            ${profile.doctorOrProfessor === "DOCTOR" ? "Dr." : (profile.doctorOrProfessor === "PROFESSOR" ? "Prof." : "")}
            ${convertToTitleCase(profile.firstName)} ${convertToTitleCase(profile.lastName)}
          `}
        </div>
        <div className="row">
          <div className="qr-code-container pt-3">
            {/*<ImageContainer img={QRCode} />*/}
            <QRCodeCanvas
              id="qrcode-canvas"
              level="H"
              value={data}
              size={400}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <ModalHelper
        size={"sm"}
        open={isModalOpen}
        onOpen={() => setIsModalOpen(true)}
        onClose={() => setIsModalOpen(false)}
        component={modalComponent}
      />
      <HeaderDoctor title={"QR Code / URL"} />
      <div className="nk-content ">
        <div className="container-fluid-doctor">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">


                <div className="container">
                  <div className="row ps-5 pe-5">
                    <div className="col-sm-12 col-md-12 col-lg-12 mt-5 mb-3 p-5 profile-container">
                      <div className="row d-flex justify-center align-start">
                        <div className="col-sm-12 col-md-10 col-lg-8 mb-5 ">
                          <ComponentToPrint ref={componentRef} component={<QRCodeWidthTitle/>} />
                          <div className="row d-flex justify-center align-center  qr-description pt-4 pb-4">
                            Présentez ce QR code ou ce lien URL aux patients pour la prise de rendez-vous!
                          </div>
                          <div className="row d-flex justify-center align-center">
                            <div className="col" style={{ maxWidth: "550px" }}>

                              <div className="row">
                                <div className={`col-sm-4 pe-0 curs-pointer ${screenWidth <= 700 ? "ps-0" : ""}`}>
                                  <div className={`btn-sm blue-button`} onClick={() => {
                                    copyToClipboard(data);
                                  }}>
                                    <BiLink size={20} color={"#fff"} className="me-1" />
                                    Copier le lien
                                  </div>
                                </div>
                                <div className={`col-sm-4 pe-0 curs-pointer ${screenWidth <= 700 ? "ps-0" : ""}`}>
                                  <div className={`btn-sm blue-button`} onClick={downloadQRCode}>
                                    <LuDownload size={20} color={"#fff"} className="me-1" />
                                    Télécharger
                                  </div>
                                </div>
                                {
                                  (!navigator.userAgent.toLowerCase().includes('android') &&
                                  !navigator.userAgent.toLowerCase().includes('iphone') &&
                                  !navigator.userAgent.toLowerCase().includes('ipad') &&
                                  !navigator.userAgent.toLowerCase().includes('ipod') )  ?

                                    <div className={`col-sm-4 pe-0 curs-pointer ${screenWidth <= 700 ? "ps-0" : ""}`}>
                                      <div className={`btn-sm blue-button disabled`} onClick={handlePrint}>
                                        <LuPrinter size={20} color={"#fff"} className="me-1" />
                                        Imprimer
                                      </div>
                                    </div> :
                                    <></>
                                }
                              </div>
                            </div>
                          </div>

                          <div className="row mt-5 d-flex justify-center align-center">
                            <div className="col" style={{ maxWidth: "550px" }}>
                              <div className="row">
                                <div className={`col pe-0 ${screenWidth <= 700 ? "ps-0" : ""}`}>
                                  <Field
                                    id={"email"}
                                    name={"email"}
                                    isRequired={true}
                                    disabled={true}
                                    type={"text"}
                                    value={data}
                                    onChange={(e) => {
                                    }}
                                  />
                                </div>
                                <div className={`col-sm-auto pe-0 ${screenWidth <= 700 ? "ps-0" : "ps-1"}`}>
                                  <div className={`btn blue-button`} onClick={() => {
                                    copyToClipboard(data);
                                  }}>
                                    <BiLink size={20} color={"#fff"} className="me-1" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  );
};

export default QrCode;
