import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Field } from "../../../../components/fouladyar/field/field";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { CgClose } from "react-icons/cg";
import axios from "../../../../shared/custome-axios";
import { ErrorToaster } from "../../../../shared/toaster";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {

  const { t, i18n } = useTranslation();

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmedPassword: ""
  });

  async function onSubmit() {
    try {

      if (data.newPassword !== data.confirmedPassword) {
        toast.error(t(`the-entered-passwords-are-not-the-same-please-check-again`));
      }

      const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/change-password`, data, {
        headers: { authorization: `bearer ${auth.token}` }
      });

      if (res.status === 200) {
        toast.success(t(`your-password-has-been-changed-successfully`));
        navigate(`/my-profile`);
      }

    } catch (e) {
      ErrorToaster(e);
    }
  }


  return (
    <>
      <SafeAreaHeader
        rightIcon={<CgClose size={24} color={"#060606"} />}
        rightIconOnclick={() => {
          navigate(-1);
        }}
      />

      <div className="scroll-fixed-area">
        <div className="scroll-container">
          <div className="container">

            <div className="row d-flex">
              <h6 className="profile-title p-1 mb-5 mt-5 text-center">{t("change-password")}</h6>
            </div>

            <div className="row  mb-4">
              <p className="text-center">
                {t("you-may-be-logged-out-of-your-account-on-some-devices-to-protect-your-account")}
              </p>
            </div>

            <div className="row mb-2">
              <Field
                id={"old-password"}
                name={"old-password"}
                label={t("your-current-password")}
                type={"password"}
                value={data.oldPassword}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    oldPassword: e
                  }));
                }}
              />
            </div>

            <div className="row mb-2">
              <Field
                id={"password"}
                name={"password"}
                label={t("new-password")}
                type={"password"}
                value={data.newPassword}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    newPassword: e
                  }));
                }}
              />
            </div>

            <div className="row mb-2">
              <Field
                id={"confirm-password"}
                name={"confirm-password"}
                label={t("confirm-new-password")}
                type={"password"}
                value={data.confirmedPassword}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    confirmedPassword: e
                  }));
                }}
              />
            </div>


          </div>
          <div className="container mb-4">
            <div className={`btn blue-button`} onClick={async () => {
              await onSubmit();
            }}>
              {t("change-password")}
            </div>
          </div>

          <div className="row d-flex justify-center">
            <Link to={`/forget-password`} className="logo-link" style={{ textAlign: "center" }}>
              {t("forgot-password")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
