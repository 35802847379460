import React from "react";
import { Icon } from "../../../../components/Component";


const SafeAreaHeader = ({title, leftIcon, rightIcon, leftIconOnclick, rightIconOnclick, styles}) => {


  return(
    <div className="container header-container" style={styles}>
      <div className="row pt-3 pb-2">
        {
          !leftIcon ? '' :
            <div className="col d-flex justify-start align-center" style={{cursor: "pointer"}} onClick={leftIconOnclick}>
              {leftIcon}
            </div>
        }
        {
          !title ? '' :
            <div className="col search-header-title">
              {title}
            </div>
        }
        {
          !rightIcon ? '' :
            <div className="col d-flex justify-end align-center" style={{cursor: "pointer"}} onClick={rightIconOnclick}>
              {rightIcon}
            </div>
        }
      </div>
    </div>
  )
};

export default SafeAreaHeader;
