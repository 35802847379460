import axios from "axios";
import { useSelector } from "react-redux";
import { selectThemeInfo } from "../redux/store/services/theme/store";

const readLang = () => {
/*  const themeState = useSelector(selectThemeInfo());
  return themeState.lng || "fr";*/

  const lang = localStorage.getItem("lang");
  return lang || "fr";

};


const instance = axios.create({

  headers: {
    "Accept-Language": readLang()
  }

});


export default instance;
