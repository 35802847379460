import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { BiChevronLeft } from "react-icons/bi";

const PrivacyAndPolicyPatient = () => {
  const auth = useSelector((state) => state.auth);
  const mode = useSelector((state) => state.search.mode);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();


  return (
    <>
      <SafeAreaHeader
        leftIcon={<BiChevronLeft size={28} color={"#555555"} />}
        leftIconOnclick={() => {
          navigate(-1);
        }}
      />
      <div className="scroll-fixed-area">
        <div className="scroll-container p-0">
          <div className="container terms-and-conditions px-0 pt-0">
            <div className="text-content">
              <div className="title p-0">
                {t("terms-conditions")}
              </div>
              <div className="paragraph">
                {t("terms-and-Conditions-for-the-CLICONSULT-emergency-medical-appointment-booking-application")} :
              </div>
              <div className="title">
                1. {t("introduction")}
              </div>
              <div className="paragraph">
                {t("welcome-to")} {t("these-general-conditions-define-the-terms-and-conditions-governing-your-use-of-our-medical-appointment-booking-application")}.
                <br /><br />
                {t("the-CLICONSULT-service-is-offered-via-the-website-and-the-CLICONSULT-and-CLICONSULT-PRO-applications-are-accessible-free-of-charge")}.
                <br /><br />
                {t("this-application-allows,-after-registration,-adult-individuals-to-connect,-through-its-intermediary,-with-healthcare-professionals-who-are-also-registered-or-at-least-listed-on-the-application")}
                <br /><br />
                {t("important-note")} : {t("the-functions-of-searching-for-healthcare-professionals-and-scheduling-appointments-are-not-a-vital-emergency-device")}.
                <br /> <br />
              </div>

              <div className="title">
                2. {t("data-protection-and-privacy")}
              </div>
              <div className="title">
                2.1 {t("GDPR-compliance")}
              </div>
              <div className="paragraph">
                {t("we-are-committed-to-protecting-your-privacy-and-ensuring-the-security-of-your-personal-data.-Our-practices-comply-with-the-General-Data-Protection-Regulation")}.
              </div>

              <div className="title">
                2.2 {t("data-collection-and-purpose")}
              </div>
              <div className="paragraph">
                {t("we-collect-and-process-personal-data-solely-for-the-purpose-of-providing-our-medical-scheduling-services")} :
                - {t("patient-information")},
                - {t("details-about-healthcare-providers")},
                - {t("appointment-schedules")}.
              </div>

              <div className="title">
                2.3 {t("user-consent")}
              </div>
              <div className="paragraph">
                {t("by-using-our-application,-you-explicitly-consent-to-the-collection")}.
              </div>

              <div className="title">
                2.4 {t("data-security")}
              </div>
              <div className="paragraph">
                {t("we-use-industry-standard-security-measures-to-protect-your-personal-data-against-unauthorized-access")}.
              </div>

              <div className="title">
                3. {t("user-rights")}
              </div>

              <div className="title">
                3.1 {t("access-and-correction")}
              </div>
              <div className="paragraph">
                {t("you-have-the-right-to-access-your-personal-data-and-correct-it-within-our-application")}.
              </div>

              <div className="title">
                3.2 {t("data-portability")}
              </div>
              <div className="paragraph">
                {t("you-have-the-right-to-receive-a-copy-of-your-personal-data-in-a-structured-commonly-used,-and-machine-readable-format")}.
              </div>

              <div className="title">
                3.3 {t("deletion")}
              </div>
              <div className="paragraph">
                {t("you-can-request-the-deletion-of-your-personal-data,-and-we-will-comply-unless-there-are-legal-reasons-to-retain-certain-information")}.
              </div>

              <div className="title">
                4. {t("legal-basis-for-processing")}
              </div>
              <div className="paragraph">
                {t("our-processing-of-personal-data-is-based-on-the-necessity-of-the-execution-of-a-contract")}
              </div>

              <div className="title">
                5. {t("cookies-and-tracking-technologies")}
              </div>
              <div className="paragraph">
                {t("we-use-cookies-and-similar-tracking-technologies-to-enhance-your-user-experience")}.
              </div>

              <div className="title">
                6. {t("changes-to-the-general-conditions")}
              </div>
              <div className="paragraph">
                {t("we-reserve-the-right-to-modify-these-General-Conditions-at-any-time.-Changes-will-be-effective-immediately-upon-posting")}.
              </div>

              <div className="title">
                7. {t("contact-information")}
              </div>
              <div className="paragraph">
                {t("if-you-have-any-questions-or-concerns-regarding-these-General-Conditions-or-our-data-practices")}
                support@cliconsult.fr
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyAndPolicyPatient;
