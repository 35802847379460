import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon, UserAvatar } from "../../../../components/Component";
import User from "../../../../images/avatar/b-sm.jpg";
import { FiHelpCircle, FiShield } from "react-icons/fi";
import { LuCalendar, LuKey, LuLogOut, LuMail, LuPhone } from "react-icons/lu";
import ModalHelper from "../../../../components/fouladyar/modal-helper/modalHelper";
import axios from "../../../../shared/custome-axios";
import { formatFrenchDate } from "../../../../shared/shared";
import { clearToken } from "../../../../redux/store/services/auth/store";
import { clearProfile } from "../../../../redux/store/services/profile/store/profile-actions";
import { validateToken } from "../../../auth";
import { LoadingState } from "../../../../components/fouladyar/loading-state/loadingState";
import toast from "react-hot-toast";
import { BsCheckLg } from "react-icons/bs";
import { Field } from "../../../../components/fouladyar/field/field";
import { clearTickets } from "../../../../redux/store/services/ticket/store";
import { useTranslation } from "react-i18next";

const MyProfile = () => {

  const { t, i18n } = useTranslation();

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState(<div>empty</div>);
  const [data, setData] = useState({
    "email": "",
    "firstName": "",
    "lastName": "",
    "dateOfBirth": "",
    "mobileNumber": "",
  })

  useEffect(()=>{
    async function loadProfile(){
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/client/account`, {
          headers: { "authorization": `bearer ${auth.token}` }
        });

        if (res.status === 200) {
          setData(prevState =>({
            ...prevState,
            ...res.data
          }))
        }

      }catch (e){
        // toast.error(e.message)
      }
    }

    loadProfile()
  }, [])

  useEffect(() => {

    async function validateAccessToken(){
      setIsLoading(true)
      const tokenValidation = await validateToken(auth.token);

      if(!tokenValidation)
        navigate(`/login`)

      setIsLoading(false)
    }
    validateAccessToken()


  }, [auth.token]);

  const handleSignout = () => {
    localStorage.removeItem("accessToken");
    dispatch(clearToken())
    dispatch(clearProfile())
    dispatch(clearTickets())
    navigate(`/login`);
  };


  function Profile() {
    return (
      <div className="container profile-container" >
        <div className="row  d-flex flex-row  justify-center align-center">
          <div className="col-sm-auto user-avatar-flex" style={{ paddingLeft: 0 }}>
            <UserAvatar className="profile-avatar" image={profile.avatar ? `${process.env.REACT_APP_S3_BUCKET}/${profile.avatar}` : User}></UserAvatar>
          </div>
        </div>

        <div className="row d-flex">
          <h6 className="profile-title p-1 text-center">{data.firstName} {data.lastName || "-"}</h6>
        </div>

        <div className="d-flex flex-row justify-content-center align-center pt-2 pb-2">
          <div className="col-8">

            <div className="row search-item-location pb-2">
              <div className="col-sm-auto w-auto padding-right-1">
                <LuMail size={19} color={"#555555"} />
              </div>
              <p className="col p-0 ps-1 profile-info-list-font">{ data.email.charAt(0).toUpperCase() + data.email.slice(1) || "-"}</p>
            </div>

            <div className="row search-item-location pb-2">
              <div className="col-sm-auto w-auto padding-right-1">
                <LuCalendar size={19} color={"#555555"} />
              </div>
              <p className="col p-0 ps-1 profile-info-list-font">{data.dateOfBirth ? formatFrenchDate(data.dateOfBirth) : "-"}</p>
            </div>

            <div className="row search-item-location pb-2">
              <div className="col-sm-auto w-auto padding-right-1">
                <LuPhone size={19} color={"#555555"} />
              </div>
              <p className="col p-0 ps-1 profile-info-list-font">{data.mobileNumber || "-"}</p>
            </div>

          </div>
        </div>


      </div>
    );
  }

  function AlertMessageModal({}){
    return(
      <div>
        <div className="d-flex flex-row justify-between">
          <div className="col-sm-auto align-center">
            <h4 style={{ padding: "0px 20px", fontSize: 15 }}>{t("delete-your-account")}</h4>
          </div>
          <div className="col-sm-auto">
            <div className="btn" onClick={()=>{}}>
              <a href="#cancel" className="close" style={{fontSize: "1.1em"}}>
                <Icon style={{color: "#000"}} name="cross-sm" onClick={(ev) => {
                  ev.preventDefault();
                  setIsModalOpen(false)
                }}
                ></Icon>
              </a>
            </div>
          </div>
        </div>
        <li className="divider" style={{margin: 0}}></li>


        <div className="text-center mt-5" style={{ margin: "30px 15px" }}>
          {t("by-deleting-the-account,-all-your-data-including-previous-appointment-history-will-be-deleted")}
        </div>


        <div className="d-flex justify-center align-center p-2" style={{ margin: "0px 0px 40px 0px !important" }}>
          <div className={`btn blue-button`} onClick={() => {
            navigate(`/delete-account`)
          }}>
            {t("delete-my-account-and-data")}
          </div>
        </div>

      </div>
    )
  }


  function FloorMenu() {

    function FloorMenuItem({ icon, title, link, onClick }) {
      return (
        <Link to={`${process.env.PUBLIC_URL}${link}`} className="p-0 m-0">

        <div className="row search-item-location pb-1">
            <div className="col-sm-auto w-auto padding-right-1">
              {icon}
            </div>
            <p className="col p-0 ps-1  list-menu-font">{title}</p>
          </div>
        </Link>
      );
    }

    return (
      <div className="d-flex flex-row justify-content-center align-center pt-2 pb-2 mt-5" style={{marginBottom: '3rem'}}>
        <div className="container">

          <FloorMenuItem link="/support"  title={t("contact-to-support")} icon={<FiHelpCircle size={20} color={"#555555"} />} />
          <div className="divider list-menu-font-divider"></div>

          <FloorMenuItem link="/terms-and-conditions"  title={t("terms-conditions")} icon={<FiShield size={20} color={"#555555"} />} />
          <div className="divider list-menu-font-divider"></div>

          <FloorMenuItem link="/privacy-policy"  title={t("privacy-policy")} icon={<FiShield size={20} color={"#555555"} />} />
          <div className="divider list-menu-font-divider"></div>

          <div className="p-0 m-0" onClick={handleSignout}>
            <div className="row search-item-location pb-1">
              <div className="col-sm-auto  w-auto padding-right-1">
                <LuLogOut size={20} color={"#555555"} />
              </div>
              <p className="col p-0 ps-1  list-menu-font">{t("logout")}</p>
            </div>
          </div>

        </div>

      </div>
    );
  }




  return (
    <div className="d-flex h-100">
      <div className="d-flex scroll-container">

        <ModalHelper
          size={'sm'}
          open={isModalOpen}
          onOpen={() => setIsModalOpen(true)}
          onClose={() => setIsModalOpen(false)}
          component={modalComponent}
        />

        {
          isLoading ? <LoadingState/> :
            <>
              <div className="d-flex row align-content-between">
                <div>

                  <Profile badges={[t("canDoAbortion"), t("canDoPediatrics")]} />

                  <Link to={`${process.env.PUBLIC_URL}/edit-profile`} className="p-0 m-0 ">
                    <div className={`btn blue-button mt-4`} onClick={() => {
                    }}>
                      {t("edit-personal-data")}
                    </div>
                  </Link>

                  <Link to={`${process.env.PUBLIC_URL}/change-password`} className="p-0 m-0">
                    <div className={`btn white-button`} onClick={() => {

                    }}>
                      {t("change-password")}

                    </div>
                  </Link>

                  <Link to={`${process.env.PUBLIC_URL}/my-profile`} className="p-0 m-0">
                    <div className={`btn white-button`} onClick={() => {
                      setModalComponent(<AlertMessageModal/>)
                      setIsModalOpen(true)
                    }}>
                      {t("delete-data-and-account")}
                    </div>
                  </Link>

                </div>
                <div className="large-padding-bottom">
                  <FloorMenu />
                </div>
              </div>
            </>
        }



      </div>
    </div>
  );
};

export default MyProfile;
