import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { BiChevronLeft } from "react-icons/bi";

const PrivacyAndPolicyPatient = () => {
  const auth = useSelector((state) => state.auth);
  const mode = useSelector((state) => state.search.mode);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();


  return (
    <>

      <SafeAreaHeader
        leftIcon={<BiChevronLeft size={28} color={"#555555"} />}
        leftIconOnclick={() => {
          navigate(-1)
        }}
      />

      <div className="scroll-fixed-area">
        <div className="scroll-container p-0">
          <div className="container terms-and-conditions px-0 pt-0">

            <div className="text-content">
              <div className="title p-0">
                {t("privacy-policy")}
              </div>
              <div className="paragraph">
                {t("thank-you-for-choosing-CLICONSULT")}
              </div>
              <div className="paragraph">
                {t("this-privacy-policy-describes-how-we-collect,-use,-and-disclose-your-personal-information-when-you-use-our-web-application")}

              </div>
              <div className="title">
                1. {t("information-we-collect")}
              </div>
              <div className="title">
                1.1 {t("informations-personnelles")} :
              </div>

              <div className="paragraph">
                {t("we-may-collect-personal-information-that-you-provide-directly-to-us")}
              </div>


              <div className="title">
                1.2 {t("appointment-data")} :
              </div>
              <div className="paragraph">
                {t("we-collect-information-related-to-your-appointments,-including-scheduling-details-session-durations-and-appointment-statuses")}
              </div>


              <div className="title">
                2. {t("comment-nous-utilisons-vos-informations")}
              </div>
              <div className="paragraph">
                {t("we-use-the-information-we-collect-for-various-purposes-including")} :
                <ul>
                  <li>
                    {t("facilitating-the-scheduling-and-management-of-appointments")},
                  </li>
                  <li>
                    {t("fournir une vue en direct des rendez-vous planifiés")},
                  </li>
                  <li>
                    {t("analyzing-appointment-history-to-improve-our-services")},
                  </li>
                  <li>
                    {t("responding-to-inquiries-and-providing-customer-support")},
                  </li>
                </ul>
              </div>


              <div className="title">
                3. {t("data-security")}
              </div>
              <div className="paragraph">
                {t("we-prioritize-the-security-of-your-data.-We-have-also-implemented-measures-to-protect-against-unauthorized-access")}.
              </div>

              <div className="title">
                4. {t("your-choices")}
              </div>
              <div className="paragraph">
                {t("you-may-choose-not-to-provide-certain-information")}.
              </div>


              <div className="title">
                5. {t("cookies-and-similar-technologies")}
              </div>
              <div className="paragraph">
                {t("we-may-use-cookies-and-similar-technologies-to-enhance-your-experience")}.
              </div>

              <div className="title">
                6. {t("user-rights")}
              </div>
              <div className="paragraph">
                {t("as-a-user-of-CLICONSULT-you-have-the-right-to-access")}.
              </div>

              <div className="title">
                7. {t("data-retention")}
              </div>
              <div className="paragraph">
                {t("we-will-retain-your-personal-information-as-long-as-necessary-for-the-purposes-described-in-this-privacy-policy")}.
              </div>

              <div className="title">
                8. {t("updates-to-this-policy")}
              </div>
              <div className="paragraph">
                {t("we-may-update-this-privacy-policy-from-time-to-time-to-reflect-changes-in-our-data-practices")}.
              </div>
              <div className="title">
                9. {t("contact-us")}
              </div>
              <div className="paragraph">
                {t("if-you-have-any-questions-about-this-privacy-policy-please-contact-us-at")}
                support@cliconsult.fr.
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyAndPolicyPatient;
