import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Field } from "../../../../components/fouladyar/field/field";
import { validateToken } from "../../../auth";
import axios from "../../../../shared/custome-axios";
import { setToken } from "../../../../redux/store/services/auth/store";
import { setProfile } from "../../../../redux/store/services/profile/store";
import toast from "react-hot-toast";
import { clearTickets } from "../../../../redux/store/services/ticket/store/ticket-actions";
import { ErrorToaster } from "../../../../shared/toaster";
import { useTranslation } from "react-i18next";

const Login = () => {

  const { t, i18n } = useTranslation();

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [IS_TOKEN_VALID, SET_IS_TOKEN_VALID] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: "",
    password: "",
    rememberMe: false
  });

  useEffect(() => {
    async function validateAccessToken() {
      const tokenValidation = await validateToken(auth.token);

      if (tokenValidation) {
        navigate(`/home`);
      } else {
        SET_IS_TOKEN_VALID(false);
      }
    }

    validateAccessToken();

  }, [auth.token]);


  function Header() {
    return (
      <>
        <div className="container">
          <div className="row d-flex justify-end align-end p-0 pb-2 pt-2">
            {/*<Link to={`${process.env.PUBLIC_URL}/my-profile`} className="header-icon p-0 m-0">*/}
            {/*   <Icon name="cross" className="p-0 text-end" style={{ fontSize: 24 }}></Icon>*/}
            {/*</Link>*/}
          </div>
        </div>
      </>
    );
  }

  async function onSubmitForm() {

    try {

      debugger;
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/login/client`, {
        email: data.email,
        password: data.password
      });

      if (res.status === 200) {
        dispatch(setToken(res.data.accessToken));
        dispatch(clearTickets());
        dispatch(setProfile({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email,
          dateOfBirth: res.data.dateOfBirth,
          avatar: res.data.avatar,
          mobileNumber: res.data.mobileNumber
        }));


        window.ReactNativeWebView?.postMessage(JSON.stringify({
          action: "logined",
          message: res.data.accessToken
        }));

       /* const firebaseToken = await localStorage.getItem("firebaseToken");
        const deviceType = await localStorage.getItem("deviceType");

        if (firebaseToken && deviceType) {
          const setNotificationTokenRes = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/client/account/notification`, {
            token: firebaseToken,
            deviceType: deviceType
          }, {
            headers: { authorization: `bearer ${res.data.accessToken}` }
          });

        }*/


        // navigate(`/home`);
      }

    } catch (e) {
      ErrorToaster(e);
    }
  }


  return (
    <>
      {
        IS_TOKEN_VALID ? <></> :
          <div className="scroll-container">

            <div className="container profile-container">
              <div className="row d-flex mt-5">
                <h6 className="profile-title p-1 text-center">{t("login")}</h6>
              </div>

              <div className="row  mb-4">
                <p className="text-center">{t("access-your-appointment")}</p>
              </div>


              <div className="row mb-2">
                <Field
                  id={"email"}
                  name={"email"}
                  label={"E-mail"}
                  type={"text"}
                  value={data.email}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      email: e
                    }));
                  }}
                />
              </div>
              <div className="row mb-2">
                <Field
                  id={"password"}
                  name={"password"}
                  label={t("password")}
                  type={"password"}
                  value={data.password}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      password: e
                    }));
                  }}
                />
              </div>

              <div className="row mb-4">
                <Field
                  id={"resterconnect"}
                  name={"resterconnect"}
                  label={t("stay-logged-in")}
                  type={"checkbox"}
                  value={data.rememberMe}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      rememberMe: !data.rememberMe
                    }));
                  }}
                />
              </div>

            </div>

            <div className="container mb-4">
              <div className={`btn blue-button`} onClick={async () => {
                await onSubmitForm();
              }}>
                {t("login")}
              </div>
            </div>

            <div className="row d-flex">
              <Link to={`${process.env.PUBLIC_URL}/forget-password`} className="p-0 m-0">
                <div className="p-1 text-center">{t("forgot-password")}</div>
              </Link>
            </div>

            <div className=" d-flex flex-row justify-center">
              <div className="col-sm-auto p-0 m-0">{t("dont-have-account")}</div>

              <Link to={`${process.env.PUBLIC_URL}/create-account`} className="p-0 m-0 w-auto">
                <div className="col-sm-auto pt-0 pt-0 p-1 m-0">{t("create-one")}</div>
              </Link>
            </div>
            <div className={"walkthrough-floor-links"}>
              <div className="d-flex flex-row justify-center">
                <Link to={`${process.env.PUBLIC_URL}/support`}>
                  <div className="w-auto pt-0 pt-0 p-1 m-0 d-flex justify-center walkthrough-floor-links-text">
                    {t("contact-to-support")}
                  </div>
                </Link>
              </div>
              <div className="d-flex flex-row justify-center">
                <Link to={`${process.env.PUBLIC_URL}/terms-and-conditions`}>
                  <div
                    className="w-auto pt-0 pt-0 p-1 m-0 d-flex justify-center walkthrough-floor-links-text">{t("terms-conditions")}
                  </div>
                </Link>
                <div>
                  |
                </div>
                <Link to={`${process.env.PUBLIC_URL}/privacy-policy`}>
                  <div className="w-auto pt-0 pt-0 p-1 m-0 d-flex justify-center walkthrough-floor-links-text">
                    {t("privacy-policy")}
                  </div>
                </Link>
              </div>
            </div>

          </div>
      }
    </>
  );
};

export default Login;
