import React, { useEffect, useState } from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import Menu, { FloorMenu } from "../menu/Menu";

import { useTheme, useThemeUpdate } from "../provider/Theme";
import headerLogo from "../../assets/images/cliconsult/headerLogo.png";
import { FiHelpCircle } from "react-icons/fi";


function SidebarLogo() {
  return (
    <div className="col-sm-auto nk-header-app-name mb-5">
      <div className="nk-header-app-logo">
        <img src={headerLogo} alt="" />
      </div>
    </div>
  );
}
const Sidebar = ({ fixed, className }) => {

  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Function to update screenWidth when the window is resized
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };


    // Add event listener for window resize
    window.addEventListener('resize', updateScreenWidth);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  const classes = classNames({
    "nk-sidebar": true,
    "nk-sidebar-fixed": fixed,
    "nk-sidebar-active": theme.sidebarVisibility,
    "nk-sidebar-mobile": theme.sidebarMobile,
    [`is-light`]: theme.sidebar === "white",
    [`is-${theme.sidebar}`]: theme.sidebar !== "white" && theme.sidebar !== "light",
    [`${className}`]: className
  });

  return (
    <>
      <div className={`${classes} d-flex flex-`}>
        <SimpleBar className="nk-sidebar-inner d-flex justify-content-between align-content-between  flex-grow-1">
          <div>
            <SidebarLogo />
            <Menu />
          </div>
          {
            screenWidth > 1000 ?
            <div className="mb-2">
              <FloorMenu />
            </div> : ''
          }

        </SimpleBar>
      </div>
      {theme.sidebarVisibility && <div
        onClick={themeUpdate.sidebarVisibility}
        className="nk-sidebar-overlay"></div>}
    </>
  );
};
export default Sidebar;
