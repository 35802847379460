import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { Field } from "../../../../components/fouladyar/field/field";
import axios from "../../../../shared/custome-axios";
import { clearToken, setToken } from "../../../../redux/store/services/auth/store";
import { clearTickets } from "../../../../redux/store/services/ticket/store";
import { setProfile } from "../../../../redux/store/services/profile/store";
import toast from "react-hot-toast";
import { validateToken } from "../../../auth";
import { ErrorToaster } from "../../../../shared/toaster";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { BiChevronLeft } from "react-icons/bi";
import { clearProfile } from "../../../../redux/store/services/profile/store/profile-actions";
import { useTranslation } from "react-i18next";

const DeleteAccount = () => {
  const { t, i18n } = useTranslation();


  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {

    async function validateAccessToken() {
      setIsLoading(true);
      const tokenValidation = await validateToken(auth.token);

      if (!tokenValidation)
        navigate(`/login`);

      setIsLoading(false);
    }

    validateAccessToken();


  }, [auth.token]);

  const [data, setData] = useState({
    "password": ""
  });

  async function onDeleteAccount() {

    try {

      const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/client/account`, {
        headers: { "authorization": `bearer ${auth.token}` },
        data: { password: data.password }
      });

      if (res.status === 200) {
        toast.success(t("your-account-has-been-successfully-updated"));
        localStorage.removeItem("accessToken");
        dispatch(clearToken());
        dispatch(clearProfile());
        navigate(`/login`);
      }

    } catch (e) {
      ErrorToaster(e);
    }
  }


  return (
    <>
      <SafeAreaHeader
        leftIcon={<BiChevronLeft size={28} color={"#555555"} />}
        leftIconOnclick={() => {
          navigate("/my-profile");
        }}
      />


      <div className="scroll-fixed-area" style={{ paddingBottom: "unset" }}>
        <div className="scroll-container">


          <div className="container">


            <div className="row d-flex mt-4">
              <h6 className="profile-title p-2">{t("delete-my-account-and-data")}</h6>
            </div>

            <div className="row mb-4">
              <p className="">
                {t("confirm-your-account-deletion-request")}
              </p>
            </div>


            <div className="row mb-5">
              <Field
                id={"password"}
                name={"password"}
                label={t("enter-password-to-confirm")}
                type={"password"}
                value={data.password}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    password: e
                  }));
                }}
              />
            </div>


          </div>

          <div className="container mb-4 bt-4">
            <div className={`btn blue-button mb-2 ${!data.password ? "disabled" : ""}`} onClick={async () => {
              if (data.password)
                await onDeleteAccount();
            }}>
              {t("delete")}
            </div>


            <div className={`btn white-button`} onClick={() => {
              navigate("/my-profile");
            }}>
              {t("back")}
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
