import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon, UserAvatar } from "../../../../components/Component";
import { Field } from "../../../../components/fouladyar/field/field";
import User from "../../../../images/avatar/b-sm.jpg";
import axios from "../../../../shared/custome-axios";
import toast, { Toaster } from 'react-hot-toast';
import { BiChevronLeft } from "react-icons/bi";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { BsCheckLg } from "react-icons/bs";
import { ErrorToaster } from "../../../../shared/toaster";
import { setProfile } from "../../../../redux/store/services/profile/store";
import { useTranslation } from "react-i18next";


const EditProfile = () => {
  const { t, i18n } = useTranslation();

  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const dispatch = useDispatch();
  const [logoImage, setLogoImage] = useState();
  const [data, setData] = useState({
    "email": "",
    "firstName": "",
    "lastName": "",
    "dateOfBirth": "",
    "mobileNumber": "",
    "image": "",
  })


  useEffect(()=>{


    async function loadProfile(){
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/client/account`, {
          headers: { "authorization": `bearer ${auth.token}` }
        });

        if (res.status === 200) {
          setData(prevState =>({
            ...prevState,
            ...res.data
          }))
        }

      }catch (e){
        toast.error(e.response.data.error || (e.response.data.message || e.message))
      }
    }

    loadProfile()
  }, [])



  async function uploadProfileImage(){
    try {
      if(profileImage){
        const formData = new FormData();
        formData.append('img', profileImage);

        const imageUpload = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/s3/img/upload`,formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (imageUpload.status === 200) {
          toast.success(t('your-profile-picture-has-been-uploaded-successfully'))
          setData(prevState => ({
            ...prevState,
            image: imageUpload.data.imageId,
          }))

          return imageUpload.data.imageId;
        }
      }
      return  data.image
    }catch (e){
      ErrorToaster(e);
    }
  }


  async function onUpdateProfile() {
    try {
      setIsLoading(true)
      const image = profileImage ? await uploadProfileImage() :  profile.avatar;
      const nData = {...data}
      delete  nData.email;
      delete  nData.subjectId;
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/client/account`, {
        ...nData,
        image: image
      },{
        headers: { "authorization": `bearer ${auth.token}` }
      });

      if (res.status === 200) {
        toast.success('Successfully toasted!')
        dispatch(setProfile({
          ...profile,
          firstName: data.firstName,
          lastName: data.lastName ,
          email: data.email ,
          dateOfBirth: data.dateOfBirth ,
          avatar: image ,
          mobileNumber: data.mobileNumber
        }))
        navigate(`/my-profile`);

      }

      setIsLoading(false)
    }catch (e){
      ErrorToaster(e);
      setIsLoading(false)
    }
  }

  return (
    <>


      <SafeAreaHeader
        leftIcon={<BiChevronLeft size={28} color={"#555555"} />}
        leftIconOnclick={()=>{
          navigate(-1);
        }}
      />
      <div className="scroll-fixed-area">
        <div className="scroll-container">
          <div className="container edit-profile-container">

            <div className="row  d-flex flex-row pb-5 justify-center align-center">
              <div className="col-sm-auto user-avatar-flex" style={{ paddingLeft: 0 }}>
                {/*<UserAvatar*/}
                {/*  useEditButton={true}*/}
                {/*  className="profile-avatar"*/}
                {/*  image={profile.avatar ? `${process.env.REACT_APP_S3_BUCKET}/${profile.avatar}` : User}*/}
                {/*></UserAvatar>*/}

                <Field
                  className={'create-profile-image-upload profile-avatar-size d-flex justify-content-center align-center'}
                  id={"image"}
                  name={"image"}
                  type={"file-upload"}
                  disableProgress={true}
                  fileUploadInitialValue={profile.avatar ? `${process.env.REACT_APP_S3_BUCKET}/${profile.avatar}` : null}
                  value={data.image}
                  fileUploadAcceptedFiles={['image/png', 'image/jpeg', 'image/heic']}
                  fileUploadType={'profile'}
                  multiple={false}
                  onChange={(e) => {
                    try {
                      if(e[0]) {
                        if (window.ReactNativeWebView && (navigator.userAgent.toLowerCase().includes('iphone') || navigator.userAgent.toLowerCase().includes('ipad'))) {
                          //Android
                          window.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'camera', message: true}));
                          setProfileImage( e[0].file)
                        }
                      }

                    }catch (e) {
                      toast.error(t('please-get-access-to-camera'))
                    }
                  }}
                />
              </div>
            </div>

            <div className="row mb-2">
              <Field
                id={"email"}
                name={"email"}
                label={t("email")}
                type={"text"}
                disabled={true}
                isRequired={true}
                value={data.email}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    email: e
                  }))
                }}
              />
            </div>
            <div className="row mb-2">
              <div className="col-sm-6">
                <Field
                  id={"firstname"}
                  name={"firstname"}
                  label={t("first-name")}
                  type={"text"}
                  isRequired={true}
                  value={data.firstName}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      firstName: e
                    }))
                  }}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  id={"lastname"}
                  name={"lastname"}
                  label={t("last-name")}
                  type={"text"}
                  isRequired={true}
                  value={data.lastName}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      lastName: e
                    }))
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-sm-6">
                <Field
                  id={"birthdate"}
                  name={"birthdate"}
                  label={t("date-of-birth")}
                  type={"date"}
                  format={'dd/MM/yyyy'}
                  isRequired={true}
                  value={data.dateOfBirth}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      dateOfBirth: e
                    }))
                  }}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  id={"phone"}
                  name={"phone"}
                  label={t("mobile-number")}
                  type={"mobile-number"}
                  value={data.mobileNumber}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      mobileNumber: e
                    }))
                  }}
                />
              </div>
            </div>


          </div>
          <div className="container mt-4 mb-4">
            <div className={`btn blue-button mb-2 ${isLoading ? 'disabled': ''}`} onClick={async () => {

              if(!isLoading)
                await onUpdateProfile()
            }}>
              {t("save")}
            </div>

            <div className={`btn white-button`} onClick={() => {
              navigate(`/my-profile`);
            }}>
              {t("cancel")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
