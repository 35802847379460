import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { Field } from "../../../../components/fouladyar/field/field";
import "./styles.css";
import { validateToken } from "../../../auth";
import axios from "axios";
import { setToken } from "../../../../redux/store/services/auth/store";
import { setProfile } from "../../../../redux/store/services/profile/store";
import toast from "react-hot-toast";
import { ErrorToaster } from "../../../../shared/toaster";
import SafeAreaHeader from "../../../patient/components/safeAreaHeader";
import { BiChevronLeft } from "react-icons/bi";

const DoctorLogin = () => {

  const navigate = useNavigate();

  const [data, setData] = useState({
    email: "",
    password: "",
    rememberMe: false
  });
  const auth = useSelector((state) => state.auth);
  const [IS_TOKEN_VALID, SET_IS_TOKEN_VALID] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function validateAccessToken() {
      const tokenValidation = await validateToken(auth.token);


      if (tokenValidation) {
        navigate(`/home`);
      } else {
        SET_IS_TOKEN_VALID(false);
      }
    }

    validateAccessToken();

  }, [auth.token]);


  function Header() {
    return (
      <>
        <div className="container">
          <div className="row d-flex justify-end align-end p-0 pb-2 pt-2">
            <Icon name="cross" className="p-0 text-end" style={{ fontSize: 24 }}></Icon>
          </div>
        </div>
      </>
    );
  }


  async function onSubmitForm() {

    try {

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/login/provider`, {
        email: data.email,
        password: data.password
      });


      console.log("login-info", res);

      if (res.status === 200) {
        dispatch(setToken(res.data.accessToken));


        dispatch(setProfile({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email,
          dateOfBirth: res.data.dateOfBirth,
          avatar: res.data.avatar,
          providerType: res.data.providerType,
          doctorOrProfessor: res.data.doctorOrProfessor,
          gender: res.data.gender,
          mobileNumber: res.data.mobileNumber,
          subjectId: res.data.subjectId
        }));

        navigate(`/home`);
      }

    } catch (e) {
      ErrorToaster(e);
      // toast.error(e.response.data.error || (e.response.data.message || e.message))
    }
  }

  return (
    <>
      {
        IS_TOKEN_VALID ? <></> :
          <>
            <div className="nk-content-auth pt-5">
              <div className="container-fluid-doctor" style={{ minHeight: "fit-content", paddingBottom: "3rem" }}>
                <div className="nk-content-inner" style={{ height: "fit-content" }}>
                  <div className="nk-content-body">
                    <div className="nk-block d-flex justify-center align-center">
                      <div className="container ">
                        <div className="ps-3 pe-3">


                          <SafeAreaHeader
                            leftIcon={<></>}
                            leftIconOnclick={() => {
                              // navigate(-1);
                            }}
                          />

                          <div className="container">
                            <div className="row d-flex">
                              <h6 className="profile-title p-1 text-center">{"Se connecter"}</h6>
                            </div>

                            <div className="row  mb-4">
                              <p className="text-center">{""}</p>
                            </div>


                            <div className="row mb-2">
                              <Field
                                id={"email"}
                                name={"email"}
                                label={"E-mail"}
                                type={"text"}
                                value={data.email}
                                onChange={(e) => {
                                  setData(prevState => ({
                                    ...prevState,
                                    email: e
                                  }));
                                }}
                              />
                            </div>
                            <div className="row mb-2">
                              <Field
                                id={"password"}
                                name={"password"}
                                label={"Mot de passe"}
                                type={"password"}
                                value={data.password}
                                onChange={(e) => {
                                  setData(prevState => ({
                                    ...prevState,
                                    password: e
                                  }));
                                }}
                              />
                            </div>

                            <div className="row mb-4">
                              <Field
                                id={"resterconnect"}
                                name={"resterconnect"}
                                label={"Rester connecté"}
                                type={"checkbox"}
                                value={data.rememberMe}
                                onChange={(e) => {
                                  setData(prevState => ({
                                    ...prevState,
                                    rememberMe: !data.rememberMe
                                  }));
                                }}
                              />
                            </div>

                          </div>
                          <div className="container mb-2">
                            <div className={`btn blue-button`} onClick={() => {
                              onSubmitForm();
                            }}>
                              Se connecter
                            </div>
                          </div>
                          <div className="row d-flex">
                            <Link to={`${process.env.PUBLIC_URL}/forget-password`}>
                              <div className="p-1 text-center">j'ai oublié mon mot de passe</div>
                            </Link>
                          </div>
                          <div className="d-flex flex-row justify-center">
                            <div className="w-auto p-0 m-0">Vous n’avez pas de compte?</div>

                            <Link to={`${process.env.PUBLIC_URL}/create-account`}>
                              <div className="w-auto pt-0 pt-0 p-1 m-0 d-flex justify-center">S’enregistrer</div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
      }
    </>


  );
};

export default DoctorLogin;
