import React, { memo, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { useSelector } from "react-redux";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import '../assets/scss/patient-layout.scss'

function RenderMap(){

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyC8WRTxWtbeRNk7_C8TcWEFvVlRoJHxB6s",
    libraries: ["places"],
    region:['FR']
  })
  const containerStyle = {
    width: '100%',
    height: 'calc(100vh - 64px)'
  };

  const center = {
    lat: 48.8566,
    lng: 2.3522
  };

  const zoom = .05

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      onLoad={map => {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        map.setOptions({maxZoom: 14});
      }}
      onUnmount={map => {
        // setMap(null)
      }}
    >
      { /* Child components, such as markers, info windows, etc. */ }
      <></>
    </GoogleMap>
  ) : <></>
}


const RightSide = ({ title, app, ...props }) => {
  const location = useLocation();

  return (
    <div className={`nk-content nk-content-map ${location.pathname === '/home' ? '' : "no-map-mobile-layout"}`} >
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block">

              <div className="right-side-view ">
                <Outlet/>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RightSide;
