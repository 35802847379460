import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { Field } from "../../../../components/fouladyar/field/field";
import axios from "../../../../shared/custome-axios";
import { blobToBase64 } from "../../../../shared/convertBlobToBase64";
import toast from "react-hot-toast";
import { BiChevronLeft } from "react-icons/bi";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { ErrorToaster } from "../../../../shared/toaster";
import { CgClose } from "react-icons/cg";
import "react-advanced-cropper/dist/style.css";
import { Cropper, CircleStencil, FixedCropper, ImageRestriction, Priority } from "react-advanced-cropper";
import ModalHelper from "../../../../components/fouladyar/modal-helper/modalHelper";
import ClickAwayListener from "../../../../components/fouladyar/field/click-away-listener";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../../../utils/Utils";


const CreateAccount = () => {

  const { t, i18n } = useTranslation();


  const navigate = useNavigate();

  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [profileImage, setProfileImage] = useState();
  const [croppedProfileImage, setCroppedProfileImage] = useState();
  const [data, setData] = useState({
    "email": "",
    "firstName": "",
    "lastName": "",
    "dateOfBirth": "",
    "mobileNumber": "",
    "password": "",
    "image": ""
  });

  // const [image, setImage] = useState({});
  const [imageBase64, setImageBase64] = useState(undefined);
  const [croppMode, setCroppMode] = useState(false);
  const [croppedImageBase64, setCroppedImageBase64] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const screenWidth = screen.width;

  const [emailValidation, setEmailValidation] = useState({
    isValid: false
  });


  async function uploadProfileImage() {
    try {
      if (croppedProfileImage) {
        const formData = new FormData();
        formData.append("img", croppedProfileImage);


        const imageUpload = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/s3/img/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" }
        });

        if (imageUpload.status === 200) {
          toast.success(t("your-profile-picture-has-been-uploaded-successfully"));
          setData(prevState => ({
            ...prevState,
            image: imageUpload.data.imageId
          }));

          return imageUpload.data.imageId;
        }
      }
      return data.image;
    } catch (e) {
      ErrorToaster(e);
    }
  }


  async function onSubmitForm() {
    try {

      if (emailValidation.isValid) {

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/client/account`, {
          ...data,
          image: await uploadProfileImage()
        });

        if (res.status === 201) {
          navigate(`/login`);
        }
      } else {
        //ErrorToaster(t("email is invalid"));
        setEmailValidation({
          isValid: false,
          message: t("email-is-invalid")
        });
      }


    } catch (e) {
      ErrorToaster(e);
    }
  }


  const cropperRef = useRef();

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const setCroppedImage = async () => {
    const croppedImage = await convertToBase64();

    const profileFile = dataURLtoFile(croppedImage, "profile.jpg");
    setCroppedProfileImage(profileFile);
    setProfileImage(profileFile);
    setCroppedImageBase64(croppedImage);
    setCroppMode(false);
  };

  const convertToBase64 = async () => {
    if (cropperRef.current) {

      const canvas = cropperRef.current.getCanvas();
      const base64ImageData = canvas.toDataURL("image/png");
      const resizedImageBase64 = await scaleDataURL(base64ImageData, 300, 300);


      return resizedImageBase64;

    }
  };


  function scaleDataURL(dataURL, maxWidth, maxHeight) {
    return new Promise((done) => {
      var img = new Image();
      img.onload = () => {
        var scale, newWidth, newHeight, canvas, ctx;
        if (img.width > maxWidth) {
          scale = maxWidth / img.width;
        } else if (img.height > maxHeight) {
          scale = maxHeight / img.height;
        } else {
          scale = 1;
        }
        newWidth = img.width * scale;
        newHeight = img.height * scale;
        canvas = document.createElement("canvas");
        canvas.height = newHeight;
        canvas.width = newWidth;
        ctx = canvas.getContext("2d");
        console.log("img", "scale", scale, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
        done(canvas.toDataURL());
      };
      img.src = dataURL;
    });
  }


  //TODO:move to tools file
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  return (

    <>
      <SafeAreaHeader
        rightIcon={<CgClose size={24} color={"#060606"} />}
        rightIconOnclick={() => {
          navigate("/login");
        }}
      />

      <div className="scroll-fixed-area">
        <div className="scroll-container">
          <div className="container">

            <div className="row d-flex">
              <h6 className="profile-title p-1 text-center">{t("login")}</h6>
            </div>

            <div className="row  mb-4">
              <p className="text-center">{t("access-your-appointment")}</p>
            </div>


            <div className="mb-2 d-flex justify-content-center align-center">

              {
                croppMode ?

                  <Modal
                    scrollable={true}
                    isOpen={isModalOpen}
                    size={screenWidth < 768 ? "sm" : "md"}
                    style={screenWidth < 768 ? { maxWidth: "80%", margin: "auto" } : { maxWidth: "" }}
                    toggle={() => {
                      setIsModalOpen(false);
                      setCroppMode(false);
                    }}
                    backdrop={true}
                    centered={true}
                  >
                    <ModalBody>
                      <Cropper
                        src={imageBase64}
                        ref={cropperRef}
                        stencilComponent={CircleStencil}
                        priority={Priority.visibleArea}
                        backgroundWrapperProps={{
                          scaleImage: false
                        }}
                        imageRestriction={ImageRestriction.fitArea}
                        onDrag={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        onDragOver={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <div className="btn button-no-color mt-1 mb-0 "
                           style={{ "background-color": "rgb(0, 139, 185)" }}
                           onClick={setCroppedImage}>
                        {t("set-selected-area")}
                      </div>
                    </ModalFooter>
                  </Modal>
                  : <></>
              }

              {!croppMode ? <Field
                  className={"create-profile-image-upload d-flex justify-content-center align-center"}
                  id={"image"}
                  name={"image"}
                  type={"file-upload"}
                  value={data.image}
                  disableProgress={true}
                  fileUploadAcceptedFiles={["image/png", "image/jpeg", "image/heic"]}
                  fileUploadType={"post"}
                  multiple={false}
                  defaultImage={profileImage}
                  onChange={async (e) => {
                    try {
                      if (e[0]) {
                        if (window.ReactNativeWebView && (navigator.userAgent.toLowerCase().includes("iphone") || navigator.userAgent.toLowerCase().includes("ipad"))) {
                          //Android
                          window.ReactNativeWebView?.postMessage(JSON.stringify({ action: "camera", message: true }));
                          //setProfileImage(e[0].file);
                        }

                        let binaryData = [];
                        binaryData.push(e[0]);

                        setImageBase64(await toBase64(e[0].file));
                        setCroppMode(true);
                        setIsModalOpen(true);
                      }

                    } catch (e) {
                      toast.error(t("please-get-access-to-camera"));
                    }
                  }}
                  onCancel={(e) => {
                    setProfileImage(null);
                  }}
                  noDrag={true}
                />
                : <></>}


            </div>


            <div className="row mb-2">
              <Field
                id={"email"}
                name={"email"}
                label={"E-mail"}
                type={"text"}
                value={data.email}
                validation={emailValidation}
                onChange={(e) => {

                  debugger;
                  if (validateEmail(e)) {
                    setEmailValidation({
                      isValid: true
                    });
                  } else {
                    setEmailValidation({
                      isValid: false,
                      message: t("email-is-invalid")
                    });
                  }

                  setData(prevState => ({
                    ...prevState,
                    email: e
                  }));
                }}
              />
            </div>

            <div className="row mb-2">
              <div className="col-sm-6">
                <Field
                  id={"firstName"}
                  name={"firstName"}
                  label={t("first-name")}
                  type={"text"}
                  isRequired={true}
                  value={data.firstName}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      firstName: e
                    }));
                  }}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  id={"lastName"}
                  name={"lastName"}
                  label={t("last-name")}
                  type={"text"}
                  isRequired={true}
                  value={data.lastName}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      lastName: e
                    }));
                  }}
                />
              </div>
            </div>


            <div className="row mb-2">
              <div className="col-sm-6">
                <Field
                  id={"dateOfBirth"}
                  name={"dateOfBirth"}
                  label={t("date-of-birth")}
                  type={"date"}
                  isRequired={true}
                  value={data.dateOfBirth}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      dateOfBirth: e
                    }));
                  }}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  id={"mobileNumber"}
                  name={"mobileNumber"}
                  label={t("mobile-number")}
                  type={"mobile-number"}
                  isRequired={true}
                  value={data.mobileNumber}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      mobileNumber: e
                    }));
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <Field
                id={"password"}
                name={"password"}
                label={t("password")}
                type={"password"}
                value={data.password}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    password: e
                  }));
                }}
              />
            </div>
            <div className="row mb-2">
              <Field
                id={"password-confirmation"}
                name={"password-confirmation"}
                label={t("confirm-password")}
                type={"password"}
                value={passwordConfirmation}
                onChange={(e) => {
                  setPasswordConfirmation(e);
                }}
              />
            </div>

            <div className="row mb-4">
              <Field
                id={"resterconnect"}
                name={"resterconnect"}
                label={t("accept-message")}
                type={"checkbox"}
                value={termsAndConditions}
                onChange={(e) => {
                  setTermsAndConditions(e);
                }}
              />
            </div>

          </div>
          <div className="container mb-4">
            <div className={`btn blue-button`} onClick={async () => {
              await onSubmitForm();
            }}>
              {t("create")}
            </div>
          </div>
          <div className="d-flex flex-row justify-center">
            <div className="col-sm-auto p-0 m-0">{t("already-have-an-account")}</div>
            <Link to="/login" className="col-sm-auto pt-0 pt-0 p-1 m-0">{t("login")}</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
