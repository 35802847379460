import { LuHome } from "react-icons/lu";
import { GrLanguage } from "react-icons/gr";
import { TbTicket } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { IoSearch } from "react-icons/io5";
import { FiUser } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import EnglishFlag from "../../images/flags/uk.png";
import FrenchFlag from "../../images/flags/french.png";
import { Field } from "../../components/fouladyar/field/field";

export const NavItem = ({ icon, text, link, isOn }) => {

  const location = useLocation();
  return (
    <Link to={`${process.env.PUBLIC_URL}${link}`}>
      <div className={`nav-item ${location.pathname === link ? "active-item" : ""}`}>
        <div className="icon"> {icon}</div>
        <span className="text">{text}</span>
        {
          !isOn ? "" : <div className="isOnMobile"></div>
        }
      </div>
    </Link>
  );
};
export const BottomNavBar = ({ ...props }) => {

  const { t, i18n } = useTranslation();
  const profile = useSelector((state) => state.profile);
  const ticket = useSelector((state) => state.ticket);
  const currentLanguage = localStorage.getItem("lang");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng).then(() => {
    });
    localStorage.setItem("lang", lng);
  };

  return (
    <div className="bottom-nav-bar">
      <NavItem link={"/home"} icon={<LuHome size={22} color={"#060606"} />} text={t("patient-home")} />
      <NavItem link={"/filter"} icon={<IoSearch size={22} color={"#060606"} />} text={t("search")} />
      <NavItem link={"/history"} icon={<TbTicket size={22} color={"#060606"} />} text={t("e-ticket")}
               isOn={ticket.active.length > 0} />
      <NavItem link={"/my-profile"} icon={profile.avatar ?
        <img className="header-profile-image" src={`${process.env.REACT_APP_S3_BUCKET}/${profile.avatar}`}
             alt={"image"} /> : <FiUser size={28} color={"#555555"} />} text={t("profile")} />
      <UncontrolledDropdown direction="down">
        <DropdownToggle color="transparent" className="dropdown-toggle nav-link">
          <div className={`nav-item `}>
            <div className="icon">
              {<GrLanguage size={22} color={"#060606"} />}
            </div>
            <span className="text p-0">
              {t('language')}
            </span>
          </div>
        </DropdownToggle>
        <DropdownMenu style={{ width: "100vw", left: 0, right: 0 }}>
          <ul className="language-list">
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  changeLanguage("en");
                }}
                className="language-item"
              >
                <img src={EnglishFlag} alt="" className="language-flag-login me-1" />
                <span className="language-name-login">{t("en-language")}</span>
                <div className="ms-auto">
                  <Field
                    id={"language"}
                    name={"language"}
                    type={"checkbox-language"}
                    className={"ml-auto p-0"}
                    value={currentLanguage === "en"}
                    onChange={() => {
                      if (currentLanguage !== "en") {
                        changeLanguage("fr");
                      }
                    }}
                  />
                </div>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  changeLanguage("fr");
                }}
                className="language-item"
              >
                <img src={FrenchFlag} alt="" className="language-flag-login me-1" />
                <span className="language-name-login">{t("fr-language")}</span>
                <div className="ms-auto">
                  <Field
                    id={"language"}
                    name={"language"}
                    type={"checkbox-language"}
                    className={"ml-auto p-0"}
                    value={currentLanguage === "fr"}
                    onChange={() => {
                      if (currentLanguage !== "fr") {
                        changeLanguage("en");
                      }
                    }}
                  />
                </div>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};
