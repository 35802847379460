import React, { useEffect, useLayoutEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// import Error404Modern from "../pages/error/404-modern";
// import Error504Modern from "../pages/error/504-modern";
import Layout from "../layout/Index";
import { apps } from "../App";
import PatientLayout from "../layout/Index-patient";
import DoctorLayout from "../layout/Index-doctor";
import ProfileInDoctorDashboard from "../pages/doctor/pages/profile";
import EditProfileInDoctorDashboard from "../pages/doctor/pages/edit-profile";
import QrCode from "../pages/doctor/pages/qr-code";
import EditThemeInDoctorDashboard from "../pages/doctor/pages/theme";
import Analyses from "../pages/doctor/pages/analyses";
import CalendarInDoctorDashboard from "../pages/doctor/pages/calendar";

import DoctorLogin from "../pages/doctor/pages/login";
import DoctorForgetPassword from "../pages/doctor/pages/forget-password";
import DoctorForgetPasswordEmailSent from "../pages/doctor/pages/forget-password-email-sent";
import PasswordConfirmation from "../pages/doctor/pages/password-confirmation";
import DoctorCreateAccount from "../pages/doctor/pages/create-account";

import DoctorHome from "../pages/doctor/pages/home";
import DoctorPatients from "../pages/doctor/pages/patients";
import SearchResults from "../pages/patient/pages/search-results";
import SearchForm from "../pages/patient/pages/search-form";
import DoctorProfile from "../pages/patient/pages/doctor-profile";
import MyProfile from "../pages/patient/pages/myProfile";
import EditProfile from "../pages/patient/pages/edit-profile";
import Login from "../pages/patient/pages/login";
import CreateAccount from "../pages/patient/pages/create-account";
import ForgetPassword from "../pages/patient/pages/forget-password";
import History from "../pages/patient/pages/history";

import '../assets/scss/doctor-layout.scss'


import { Chart, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Filler, Legend, } from "chart.js";
import RightSide from "../layout/Index-patient-right-side";
import LeftSide from "../layout/Index-patient-left-side";
import DoctorTakeAppointmentStarter from "../pages/patient/pages/doctor-take-appointment-starter";
import DoctorTakeAppointmentForm from "../pages/patient/pages/doctor-take-appointment-form";
import ContactUs from "../pages/doctor/pages/contact-us";
import TermsAndConditions, { TermsAndConditionsComponent } from "../pages/doctor/pages/terms-and-conditions";
import { useDispatch, useSelector } from "react-redux";
import { clearToaster } from "../redux/store/services/toaster/store/toaster-actions";
import Ticket from "../pages/patient/pages/ticket";
import { Toaster } from "react-hot-toast";
import PrivacyPolicy from "../pages/doctor/pages/privacy-policy";
import TermsAndConditionsPatient from "../pages/patient/pages/temsAndConditions";
import PrivacyAndPolicyPatient from "../pages/patient/pages/privacyAndPolicy";
import DeleteAccount from "../pages/patient/pages/delete-account";
import Walkthrough from "../pages/patient/pages/walkthrough";
import ContactUsPatient from "../pages/patient/pages/contact-us";
import DoctorWalkthrough, { DoctorWalkthroughLeft } from "../pages/doctor/pages/walkthrough";
import DoctorLayoutWalkthrough from "../layout/Index-doctor-walkthrough";
import { TemrsAndConditionsWalkthrough } from "../pages/patient/pages/temsAndConditions/temrsAndConditionsWalkthrough";
import { PrivacyAndPolicyWalkthrough } from "../pages/patient/pages/temsAndConditions/PrivacyAndPolicyWalkthrough";
import { ContactSupportWalkthrough } from "../pages/patient/pages/temsAndConditions/contactSupportWalkthrough";
import DeleteAccountPateint from "../pages/doctor/pages/delete-account";
import ResetPassword from "../pages/patient/pages/reset-password";
import ChangePassword from "../pages/patient/pages/change-password";
import DoctorResetPassword from "../pages/doctor/pages/reset-password";
import DoctorChangePassword from "../pages/doctor/pages/change-password";
Chart.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Filler, Legend, );

const DoctorRouters = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const dispatch = useDispatch();
  const toaster = useSelector((state) => state.toaster);


  return (
    <div className="doctor-layout">
      <Routes>

          <Route path="" element={<DoctorLayoutWalkthrough />}>
            <Route index element={<DoctorWalkthroughLeft />}></Route>
            <Route path="/download" element={<></>}></Route>
            <Route path="/terms-and-conditions" element={<TemrsAndConditionsWalkthrough/>}></Route>
            <Route path="/privacy-policy" element={<PrivacyAndPolicyWalkthrough />}></Route>
            <Route path="/support" element={<ContactSupportWalkthrough />}></Route>

          </Route>

          <Route path="" element={<DoctorLayout />}>
            <Route path="/home" element={<DoctorHome />}></Route>
            <Route path="/patients" element={<DoctorPatients />}></Route>
            <Route path="/profile" element={<ProfileInDoctorDashboard />}></Route>
            <Route path="/edit-profile" element={<EditProfileInDoctorDashboard />}></Route>
            <Route path="/delete-account" element={<DeleteAccountPateint />}></Route>
            <Route path="/qr-code" element={<QrCode />}></Route>
            <Route path="/account/support" element={<ContactUs />}></Route>
            <Route path="/account/change-password" element={<DoctorChangePassword />}></Route>
            <Route path="/account/terms-and-conditions" element={<TermsAndConditions />}></Route>
            <Route path="/account/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route path="/theme" element={<EditThemeInDoctorDashboard />}></Route>
            <Route path="/analyses" element={<Analyses />}></Route>
            <Route path="/calendar" element={<CalendarInDoctorDashboard />}></Route>
          </Route>

          <Route path="/login" element={<DoctorLogin />}></Route>
          <Route path="/forget-password" element={<DoctorForgetPassword />}></Route>
          <Route path="/password-confirmation" element={<PasswordConfirmation />}></Route>
          <Route path="/email-sent" element={<DoctorForgetPasswordEmailSent />}></Route>
          <Route path="/create-account" element={<DoctorCreateAccount />}></Route>
          <Route path="/reset-password" element={<DoctorResetPassword />}></Route>
          {/*<Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>*/}
          {/*<Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>*/}




      </Routes>
    </div>
  );
};


const PatientRouter = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  return (
    <div className="patient-layout">


      <Routes>

        <Route path="" >
          <Route index path="" element={<Walkthrough />}></Route>
          <Route path="" element={<PatientLayout />}>
            {/*<Route index element={<Homepage />}></Route>*/}

            <Route path="" element={<LeftSide />}>
              <Route path="/home" element={<SearchResults />}></Route>
              <Route path="/filter" element={<SearchForm />}></Route>
              <Route path="/doctor-profile/:id" element={<DoctorProfile />}></Route>
            </Route>

            <Route path="" element={<RightSide />}>
              <Route path="/my-profile" element={<MyProfile />}></Route>
              <Route path="/edit-profile" element={<EditProfile />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/reset-password" element={<ResetPassword />}></Route>
              <Route path="/change-password" element={<ChangePassword />}></Route>
              <Route path="/privacy-policy" element={<PrivacyAndPolicyPatient />}></Route>
              <Route path="/terms-and-conditions" element={<TermsAndConditionsPatient />}></Route>
              <Route path="/delete-account" element={<DeleteAccount />}></Route>
              <Route path="/support" element={<ContactUsPatient />}></Route>
              <Route path="/forget-password" element={<ForgetPassword />}></Route>
              <Route path="/history" element={<History />}></Route>
              <Route path="/doctor-take-appointment-starter/:id" element={<DoctorTakeAppointmentStarter />}></Route>
              <Route path="/doctor-take-appointment-form/:id" element={<DoctorTakeAppointmentForm />}></Route>
              <Route path="/ticket/:providerSubjectId/:appointmentId" element={<Ticket />}></Route>
              {/*<Route path="/password-confirmation" element={<PasswordConfirmation />}></Route>*/}
              {/*<Route path="/email-sent" element={<DoctorForgetPasswordEmailSent />}></Route>*/}
              <Route path="/create-account" element={<CreateAccount />}></Route>
            </Route>

            <Route path="/walkthrough" element={<Walkthrough />}></Route>
          </Route>
        </Route>




      </Routes>
    </div>
  );
};

const Pages = ({ app }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  if (app === apps.patient) {
    return (
      <PatientRouter />
    );
  }

  if (app === apps.doctor) {
    return (
      <DoctorRouters />
    );
  }

  return (

    <Routes>
      <Route path="" element={<Layout />}>
        {/*<Route index element={<4 />}></Route>*/}

      </Route>
    </Routes>
  );
};
export default Pages;
