import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon, UserAvatar } from "../../../../components/Component";
import User from "../../../../assets/images/cliconsult/default-avatar.png";
import { FiCalendar, FiCreditCard, FiHelpCircle, FiMapPin, FiPhone, FiShield } from "react-icons/fi";
import { LuLogOut } from "react-icons/lu";
import ModalHelper from "../../../../components/fouladyar/modal-helper/modalHelper";
import HeaderDoctor from "../../../../layout/header/Header-doctor";
import { HiOutlineMail } from "react-icons/hi";
import { TbBrandPocket } from "react-icons/tb";
import { AlertMessage } from "../../componeonts/alertMessage/alertMessage";
import axios from "axios";
import { convertToTitleCase } from "../../../../shared/textTools";
import { useTranslation } from "react-i18next";
import { MdTranslate } from "react-icons/md";
import toast from "react-hot-toast";
import moment from "moment/moment";

const ProfileInDoctorDashboard = () => {
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.profile);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState(<div>empty</div>);
  const [data, setData] = useState({
    "email": "",
    "gender": "",
    "firstName": "",
    "lastName": "",
    "dateOfBirth": "",
    "mobileNumber": "",
    "specialization": "",
    "rppsNumber": null,
    "adeliNumber": null,
    "officeAddress": "",
    "officePhoneNumber": "",
    "city": "",
    "longitude": null,
    "latitude": null,
    "paymentTypes": [],
    "zipCode": "zipCode_2795505c12ee",
    "note": "note_f1f09ccafe63",
    "acceptVitalCard": null,
    "canDoPediatrics": null,
    "canDoAbortion": null,
    "canDoUltrasound": null,
    "doctorOrProfessor": ""
  })

  useEffect(()=>{
    async function loadProfile(){
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/provider/account`, {
          headers: { "authorization": `bearer ${auth.token}` }
        });

        if (res.status === 200) {
          setData(prevState =>({
            ...prevState,
            ...res.data
          }))
        }

      }catch (e){

        toast.error(e.message)
      }
    }

    loadProfile()
  }, [])


  function ProfileInformationItem({ icon, value, isBadge }) {
    return (
      <div className="row search-item-location pb-3">
        <div className="col-sm-auto w-auto padding-right-1">
          {icon}
        </div>
        {
          !isBadge ? <p className="col p-0 ps-1 profile-info-text">{value}</p> : (
            Array.isArray(value) ? value.map(item => {
              return(
                <div className="data-badge">{item}</div>
              )
            }) : '-'
          )

        }
      </div>
    );
  }

  return (

    <React.Fragment>

      <ModalHelper
        size={"sm"}
        open={isModalOpen}
        onOpen={() => setIsModalOpen(true)}
        onClose={() => setIsModalOpen(false)}
        component={modalComponent}
      />

      <HeaderDoctor title={"Profil"} />
      <div className="nk-content ">
        <div className="container-fluid-doctor">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">

                <div className="container">
                  <div className="row ps-4 pe-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 mt-5 mb-3 p-5 profile-container">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4 mb-5">
                          <div className="row  d-flex flex-row  justify-center align-center">
                            <div style={{ paddingLeft: 0, width: "auto" }}>
                              <UserAvatar className="profile-avatar" image={profile.avatar ? `${process.env.REACT_APP_S3_BUCKET}/${profile.avatar}` : User}></UserAvatar>
                            </div>
                          </div>
                          <div className="row d-flex">
                            <h6 className="profile-title p-3 text-center">{`
                            ${data.gender === "MALE" ? "M." : (data.gender === "FEMALE" ? "Mme" : "Inconnu")} 
                            ${data.doctorOrProfessor === "DOCTOR" ? 'Dr.' : (data.doctorOrProfessor === "PROFESSOR" ? "Prof." : "")}
                            ${convertToTitleCase(data.firstName)} ${convertToTitleCase(data.lastName)}`}</h6>
                          </div>
                          <div className="row">
                            <p className="profile-bio p-3 text-center">{t(data.specialization)}</p>
                          </div>
                          <div className="d-flex flex-row p-3 justify-center align-center">
                            {data.canDoAbortion ? <div className="data-badge">IVG</div> : ''}
                            {data.canDoPediatrics ? <div className="data-badge">Pédiatrie</div> : ''}
                            {data.canDoUltrasound ? <div className="data-badge">Échographie</div> : ''}

                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                          <div className="row mb-5">
                            <div className="profile-bio">
                              {data.note}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-xxl-6">

                              <ProfileInformationItem value={convertToTitleCase(data.email) || '-'} icon={<HiOutlineMail size={20} color={"#555555"} />} />
                              <ProfileInformationItem value={moment(new Date(data.dateOfBirth)).format('DD/MM/YYYY') || '-'} icon={<FiCalendar size={20} color={"#555555"} />} />
                              <ProfileInformationItem value={data.mobileNumber || '-'} icon={<FiPhone size={20} color={"#555555"} />} />
                              <ProfileInformationItem value={`Numéro RPPS : ${data.rppsNumber || '-'}`} icon={<TbBrandPocket size={20} color={"#555555"} />} />
                              <ProfileInformationItem value={`Numéro ADELI: ${data.adeliNumber || '-'}`} icon={<TbBrandPocket size={20} color={"#555555"} />} />
                              <ProfileInformationItem value={data.languages ? data.languages.map(i=>convertToTitleCase(i.toLowerCase())) : '-'} isBadge={true} icon={<MdTranslate   size={20} color={"#555555"} />} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-xxl-6">

                              <ProfileInformationItem value={data.paymentTypes.map(i=>t(i)) || '-'} isBadge={true} icon={<FiCreditCard  size={20} color={"#555555"} />} />
                              <ProfileInformationItem value={`${t('cart-vital')} : ${data.acceptVitalCard || 'Non'}`} icon={<FiShield size={20} color={"#555555"} />} />
                              <ProfileInformationItem value={`${t('address')}: ${data.officeAddress || '-'}`} icon={<FiMapPin size={20} color={"#555555"} />} />
                              <ProfileInformationItem value={`${t('office-phone-number')}: ${data.officePhoneNumber || '-'}`} icon={<FiPhone size={20} color={"#555555"} />} />
                              <ProfileInformationItem value={`${t('city')}: ${data.city || '-'}`} icon={<FiMapPin size={20} color={"#555555"} />} />
                              <ProfileInformationItem value={`${t('zip-code')}: ${data.zipCode || '-'}`} icon={<FiMapPin size={20} color={"#555555"} />} />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12  col-md-12 col-lg-12 mb-3">
                      <div className="container ps-0 pe-0">
                        <div className="row  align-end justify-end">
                          <div className="col-sm-12 col-md-6 col-lg-4 ps-0 pe-0">

                            <Link to={`${process.env.PUBLIC_URL}/edit-profile`}>
                              <div className={`btn blue-button`}>
                                {t("edit-personal-data")}
                              </div>
                            </Link>


                            <Link to={`${process.env.PUBLIC_URL}/account/change-password`}>
                              <div className={`btn white-button`}>
                                {t("change-password")}
                              </div>
                            </Link>


                            <div className={`btn white-button`} onClick={() => {
                              setModalComponent(<AlertMessage onClose={() => {
                                setIsModalOpen(false);
                              }} />);
                              setIsModalOpen(true);
                            }}>
                              {t("delete-data-and-account")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  );
};

export default ProfileInDoctorDashboard;
